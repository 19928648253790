import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentApprovalConstant } from "./DocumentApprovalConstant";

const DOCUMENT_APPROVAL_CONSTANT = DocumentApprovalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_DOCUMENT_APPROVAL_COL_DEF: any[] = [
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.PREVIEWREF_NO,
        field: 'id',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CUSTOMER_NAME1,
        field: 'customerName1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CUSTOMER_NAME2,
        field: 'customerName2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.DEP_DATE_TIME,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "date"
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.ERROR_MESSAGE,
        field: 'errorMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.GENERATION_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.GENERATION_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "date"
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.CONTRACT_CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.BILLING_TYPE,
        field: 'billingType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOCUMENT_APPROVAL_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },

    //]?.map((col, index) => ({ ...col, header: col.headerName, id: index + 1, dataType: 'text' }));

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    // cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    // externalFnctions['dtlState'] = calculateStateColor;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
