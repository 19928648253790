import { EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA } from "domain/entity/DocumentApproval/DocumentApprovalSearchCriteria";
import moment from "moment";
import { useDocumentApprovalVM } from "presentation/hook/DocumentApproval/useDocumentApprovalVM";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentApprovalTitleBar from "./DocumentApprovalTitleBar";
import DocumentApprovalSearchPanel from "./LeftPanel/DocumentApprovalSearchPanel";
import DocumentApprovalTablePanel from "./Table/DocumentApprovalTablePanel";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";

const DocumentApprovalMaintenance:React.FC = () => {
    const [documentApprovalState] = useDocumentApprovalTracked();
    const { isShowCriteriaPanel } = documentApprovalState;
    const documentApprovalVM = useDocumentApprovalVM();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initialScreen = async() => {   
            try {
                let newSearchCriteria = {...EMPTY_DOCUMENT_APPROVAL_SEARCH_CRITERIA} 
                    newSearchCriteria = { ...newSearchCriteria, 
                        genDateFrom: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
                        genDateTo: moment().endOf("day").toDate(), 
                    };

                const results = await Promise.allSettled([
                    documentApprovalVM.loadDropdownOption(),
                    documentApprovalVM.onSearch(newSearchCriteria),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
                setIsLoading(false)
            }
        }
        initialScreen().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [documentApprovalVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
   
    return <>
        <div className="main-comp-wrapper">
           <DocumentApprovalTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<DocumentApprovalSearchPanel/>}
                rightChildren={<DocumentApprovalTablePanel/>} 
                />
        </div>
    </>
}
export default memo(DocumentApprovalMaintenance);